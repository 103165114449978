import React from 'react';

export default function Mail() {
  return (
    <svg width={87} height={87} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 14.5h58c3.987 0 7.25 3.262 7.25 7.25v43.5c0 3.987-3.263 7.25-7.25 7.25h-58c-3.988 0-7.25-3.263-7.25-7.25v-43.5c0-3.988 3.262-7.25 7.25-7.25Z"
        stroke="#60B590"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.75 21.75 43.5 47.125 7.25 21.75"
        stroke="#60B590"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
