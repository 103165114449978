import React, { useState } from 'react';
import Mail from '../../images/svg-gcbooks/mail';
import { navigate } from 'gatsby';

export default function Success() {
  const msg = '  Didn’t receive the email? ';

  //handle resend
  const handleResend = () => {
    navigate('/forgot-password');
  };
  return (
    <div className="loginDetails">
      <div className="loginSignUpInputs">
        <div className="mailicon">
          <Mail />
        </div>

        <h4 className="loginSignUpHeader">Check your email</h4>
        <p className="loginSignUpInfo">We sent a password reset link to your email</p>
        <div className="loginSignUpForm " id="success-msg">
          <div className="register " onClick={handleResend}>
            {msg}
            <a className="btn-register">Resend</a>
          </div>
          <div className="register">
            <a href="/login" className="btn-login">
              Back to Log In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
