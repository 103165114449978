import { graphql, Link } from 'gatsby';
import React from 'react';
import Success from '../components/Auth/success';
import BackgroundVideo from '../components/Homepage/BackgroundVideo';
import logo from '../images/logo.svg';
export default function ResetSuccess({ data }) {
  const { backgroundVideos } = data;

  return (
    <div className="loginPageContainer">
      <div className="loginSide">
        <div className="video-container">
          <BackgroundVideo videos={backgroundVideos.files.slice(1)} LoginSignup={true} />
          <div className="over-lay-signinup"></div>
        </div>
        <div className="logLoginSignUP">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <h2>Identity. Purpose. Mission.</h2>
      </div>
      <Success />
    </div>
  );
}

export const query = graphql`
  query ResetSuccess {
    backgroundVideos: allS3Object {
      files: nodes {
        url
        Key
      }
    }
  }
`;
